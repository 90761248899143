<template>
  <div>
    <el-row :gutter="20">
      <!-- TURNOS -->
      <el-col :span="18">
        <el-card :body-style="{ padding: '25px' }">
          <!-- FILTROS TABLA -->
          <div slot="header">
            <div style="margin-left: 0px; display: flex">
              <div class="select2">
                <el-select
                  v-model="selectTipoBusqueda"
                  slot="prepend"
                  style="width: 140px"
                >
                  <el-option label="Fecha" :value="1"></el-option>
                  <el-option label="Localidad" :value="2"></el-option>
                  <el-option label="Barrio" :value="3"></el-option>
                </el-select>
              </div>
              <div class="buscador2">
                <!-- Fecha -->
                <el-date-picker
                  v-model="fecha"
                  v-show="selectTipoBusqueda == 1"
                  type="daterange"
                  align="center"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                  unlink-panels
                  range-separator="a"
                  start-placeholder="Fecha desde"
                  end-placeholder="Fecha hasta"
                  :picker-options="pickerOptions"
                  @change="actualizarTablaFuncion()"
                >
                </el-date-picker>
                <!-- Localidad -->
                <maca-select-box
                  v-show="selectTipoBusqueda == 2"
                  :url="urlSubarea"
                  v-model="subarea"
                  :clearable="true"
                  itemLabel="descripcion"
                  placeholder="Seleccione localidad"
                  @change="actualizarTablaFuncion()"
                >
                </maca-select-box>
                <!-- Barrio -->
                <maca-select-box
                  v-show="selectTipoBusqueda == 3"
                  :url="urlSeccion"
                  v-model="seccion"
                  :clearable="true"
                  itemLabel="descripcion"
                  placeholder="Seleccione barrio"
                  @change="actualizarTablaFuncion()"
                  :necesitaParams="true"
                  :getParams="getParamsSeccion"
                  :permitirInput="true"
                >
                </maca-select-box>
              </div>
            </div>
            <el-button
              class="botonmasIcono"
              icon="el-icon-plus"
              type="primary"
              round
              style="margin-right: 0px"
              @click="$refs.modalNuevo.abrir()"
              >Nuevo</el-button
            >
          </div>
          <!-- TABLA -->
          <div>
            <maca-datatable
              :url="urlTabla"
              :params="paramsTabla"
              :actualizar.sync="actualizarTabla"
              :bloquear.sync="bloquearTabla"
              @tabla-cargada="(datos) => cargarDatosEnCalendario(datos)"
            >
              <el-table-column type="expand">
                <template slot-scope="props">
                  <div
                    v-for="(item, index) in props.row.detalleTurno"
                    :key="index"
                    style="display: flex; margin-bottom: 10px"
                  >
                    <div style="width: 100%; margin: auto; margin-left: 30px">
                      <el-row :gutter="20">
                        <el-col
                          :span="4"
                          v-for="(itemTT, index) in item.detalleTurnoTecnico"
                          :key="index"
                        >
                          <el-tag effect="plain" v-if="itemTT.disponible" plain>
                            <span>
                              {{
                                $maca.common.formatearHora(item.horaInicio) +
                                " - " +
                                $maca.common.formatearHora(item.horaFin) +
                                " - "
                              }}
                            </span>
                            <span v-if="itemTT.detalleUser != null">
                              {{ itemTT.detalleUser.username }}
                            </span>
                          </el-tag>
                          <el-tag effect="light" v-if="!itemTT.disponible">
                            <span>
                              {{
                                $maca.common.formatearHora(item.horaInicio) +
                                " - " +
                                $maca.common.formatearHora(item.horaFin) +
                                " - "
                              }}
                            </span>
                            <span v-if="itemTT.detalleUser != null">
                              {{ itemTT.detalleUser.username }}
                            </span>
                          </el-tag>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                :min-width="120"
                label="
          Fecha"
              >
                <template slot-scope="props">
                  <span
                    v-text="$maca.common.formatearFecha(props.row.fecha)"
                  ></span>
                </template>
              </el-table-column>

              <el-table-column :min-width="120" label="Horario">
                <template slot-scope="props">
                  <span
                    v-text="
                      $maca.common.formatearHora(props.row.horaInicio) +
                      ' - ' +
                      $maca.common.formatearHora(props.row.horaFin)
                    "
                  ></span>
                </template>
              </el-table-column>
              <el-table-column :min-width="120" label="Provincia">
                <template slot-scope="props">
                  <div v-if="props.row.detalleArea != null">
                    <span v-text="props.row.detalleArea.descripcion"></span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :min-width="120" label="Localidad">
                <template slot-scope="props">
                  <div v-if="props.row.detalleSubarea != null">
                    <span v-text="props.row.detalleSubarea.descripcion"></span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :min-width="120" label="Barrios">
                <template slot-scope="props">
                  <p
                    v-for="(item, index) in props.row.detalleTurnoSeccion"
                    :key="index"
                  >
                    {{ item.detalleSeccion.descripcion }}
                  </p>
                </template>
              </el-table-column>

              <!-- <el-table-column label="Editar" width="70">
            <template slot-scope="props">
              <el-button
                type="primary"
                circle
                @click="$refs.modalModificar.abrir(props.row.id)"
                :disabled="!$store.getters.tienePermiso('M_USE')"
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </template>
          </el-table-column> -->
              <el-table-column label="Borrar" width="70">
                <template slot-scope="props">
                  <el-button
                    type="danger"
                    @click="eliminar(props.row.id)"
                    circle
                    :disabled="!$store.getters.tienePermiso('B_USE')"
                  >
                    <i class="el-icon-delete"></i>
                  </el-button>
                </template>
              </el-table-column>
            </maca-datatable>
          </div>
        </el-card>
      </el-col>
      <!-- CALENDARIO -->
      <el-col :span="6">
        <el-card>
          <h4 style="color: #909399">Turnos disponibles</h4>

          <div style="display: flex; margin-top: 5px; color: #606266">
            <div class="circuloVerde"></div>
            <span> + 75%</span>
          </div>
          <div style="display: flex; margin-top: 5px; color: #606266">
            <div class="circuloAmarillo"></div>
            <span> + 50%</span>
          </div>
          <div style="display: flex; margin-top: 5px; color: #606266">
            <div class="circuloNaranja"></div>
            <span> + 25%</span>
          </div>
          <div
            style="
              display: flex;
              margin-top: 5px;
              margin-bottom: 20px;
              color: #606266;
            "
          >
            <div class="circuloRojo"></div>
            <span> - 25%</span>
          </div>
          <vc-date-picker
            :rows="2"
            is-expanded
            :attributes="attrs"
            v-model="fecha"
          ></vc-date-picker>
        </el-card>
      </el-col>
    </el-row>

    <modal-nuevo
      ref="modalNuevo"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-nuevo>
    <modal-modificar
      ref="modalModificar"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-modificar>
  </div>
</template>

<script>
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";

export default {
  name: "turno",
  components: {
    ModalNuevo,
    ModalModificar,
  },
  data() {
    return {
      //calendario
      attrs: [
        {
          key: "today",
          highlight: {
            fillMode: "light",
          },
          dates: new Date(),
        },
      ],
      //TABLA
      urlTabla: "/turno/obtenerTodos",
      urlSubarea: "/subarea/obtenerTodos",
      urlSeccion: "/seccion/obtenerTodos",
      paramsTabla: {},
      actualizarTabla: true,
      bloquearTabla: true,
      fecha: null,
      subarea: null,
      selectTipoBusqueda: 1,
      seccion: null,

      //opciones del rango de fechas
      pickerOptions: {
        shortcuts: [
          {
            text: "Hoy",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Última semana",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Último mes",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    getParamsSeccion(query) {
      let params = { descripcion: query };
      return params;
    },
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el usuario."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post("/turno/eliminar", params);
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Turno borrado con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    async toogleBloqueado(fila) {
      this.bloquearTabla = true;

      // Hacer post
      let params = { id: fila.id };
      let respuestaApi = null;

      if (fila.bloqueado) {
        respuestaApi = await this.$maca.api.post("/user/bloquear", params);
      } else {
        respuestaApi = await this.$maca.api.post("/user/desbloquear", params);
      }
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado != 1) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    actualizarTablaFuncion() {
      if (this.selectTipoBusqueda == 1) {
        if (this.fecha)
          this.paramsTabla = {
            fechaDesde: this.fecha[0],
            fechaHasta: this.fecha[1],
          };
        else this.paramsTabla = {};
        this.actualizarTabla = true;
      } else if (this.selectTipoBusqueda == 2) {
        if (this.subarea) this.paramsTabla = { subareaID: this.subarea.id };
        else this.paramsTabla = {};
        this.actualizarTabla = true;
      } else if (this.selectTipoBusqueda == 3) {
        if (this.seccion) this.paramsTabla = { seccionID: this.seccion.id };
        else this.paramsTabla = {};
        this.actualizarTabla = true;
      }
    },
    cargarDatosEnCalendario(fechas) {
      this.attrs = [];
      fechas.datos.forEach((fecha) => {
        let contadorTotal = 0;
        let contadorDisponibles = 0;
        let contadorOcupados = 0;
        fecha.detalleTurno.forEach((turno) => {
          turno.detalleTurnoTecnico.forEach((turnoTecnico) => {
            contadorTotal += 1;
            if (turnoTecnico.disponible) contadorDisponibles += 1;
            else contadorOcupados += 1;
          });
        });
        let item = {
          dates: fecha.fecha.replace(/-/g, "/"),
        };
        let porcentajeDisponible = (contadorDisponibles * 100) / contadorTotal;
        if (porcentajeDisponible >= 75) {
          item.highlight = {
            color: "green",
            fillMode: "light",
          };
        } else if (porcentajeDisponible >= 50) {
          item.highlight = {
            color: "yellow",
            fillMode: "light",
          };
        } else if (porcentajeDisponible >= 25) {
          item.highlight = {
            color: "orange",
            fillMode: "light",
          };
        } else if (porcentajeDisponible < 25) {
          item.highlight = {
            color: "red",
            fillMode: "light",
          };
        }
        this.attrs.push(item);
      });
    },
  },
  watch: {
    filtroNombre: function (n, o) {
      this.paramsTabla.nombre = this.filtroNombre;
      this.actualizarTabla = true;
    },
    fecha: function (n, o) {
      this.paramsTabla.fechaDesde = this.$maca.common.formatearFechaTipoApi(
        this.fecha
      );
      this.paramsTabla.fechaHasta = this.$maca.common.formatearFechaTipoApi(
        this.fecha
      );
      this.actualizarTabla = true;
    },
  },
};
</script>
<style scoped>
.circuloVerde {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #c9e9da;
}
.circuloAmarillo {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #eff1c9;
}
.circuloNaranja {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #efe5cf;
}
.circuloRojo {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #efd7da;
}
</style>
