var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 18 } },
            [
              _c("el-card", { attrs: { "body-style": { padding: "25px" } } }, [
                _c(
                  "div",
                  { attrs: { slot: "header" }, slot: "header" },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: { "margin-left": "0px", display: "flex" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "select2" },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "140px" },
                                attrs: { slot: "prepend" },
                                slot: "prepend",
                                model: {
                                  value: _vm.selectTipoBusqueda,
                                  callback: function($$v) {
                                    _vm.selectTipoBusqueda = $$v
                                  },
                                  expression: "selectTipoBusqueda"
                                }
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "Fecha", value: 1 }
                                }),
                                _c("el-option", {
                                  attrs: { label: "Localidad", value: 2 }
                                }),
                                _c("el-option", {
                                  attrs: { label: "Barrio", value: 3 }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "buscador2" },
                          [
                            _c("el-date-picker", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.selectTipoBusqueda == 1,
                                  expression: "selectTipoBusqueda == 1"
                                }
                              ],
                              attrs: {
                                type: "daterange",
                                align: "center",
                                format: "dd/MM/yyyy",
                                "value-format": "yyyy-MM-dd",
                                "unlink-panels": "",
                                "range-separator": "a",
                                "start-placeholder": "Fecha desde",
                                "end-placeholder": "Fecha hasta",
                                "picker-options": _vm.pickerOptions
                              },
                              on: {
                                change: function($event) {
                                  return _vm.actualizarTablaFuncion()
                                }
                              },
                              model: {
                                value: _vm.fecha,
                                callback: function($$v) {
                                  _vm.fecha = $$v
                                },
                                expression: "fecha"
                              }
                            }),
                            _c("maca-select-box", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.selectTipoBusqueda == 2,
                                  expression: "selectTipoBusqueda == 2"
                                }
                              ],
                              attrs: {
                                url: _vm.urlSubarea,
                                clearable: true,
                                itemLabel: "descripcion",
                                placeholder: "Seleccione localidad"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.actualizarTablaFuncion()
                                }
                              },
                              model: {
                                value: _vm.subarea,
                                callback: function($$v) {
                                  _vm.subarea = $$v
                                },
                                expression: "subarea"
                              }
                            }),
                            _c("maca-select-box", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.selectTipoBusqueda == 3,
                                  expression: "selectTipoBusqueda == 3"
                                }
                              ],
                              attrs: {
                                url: _vm.urlSeccion,
                                clearable: true,
                                itemLabel: "descripcion",
                                placeholder: "Seleccione barrio",
                                necesitaParams: true,
                                getParams: _vm.getParamsSeccion,
                                permitirInput: true
                              },
                              on: {
                                change: function($event) {
                                  return _vm.actualizarTablaFuncion()
                                }
                              },
                              model: {
                                value: _vm.seccion,
                                callback: function($$v) {
                                  _vm.seccion = $$v
                                },
                                expression: "seccion"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "botonmasIcono",
                        staticStyle: { "margin-right": "0px" },
                        attrs: {
                          icon: "el-icon-plus",
                          type: "primary",
                          round: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.$refs.modalNuevo.abrir()
                          }
                        }
                      },
                      [_vm._v("Nuevo")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "maca-datatable",
                      {
                        attrs: {
                          url: _vm.urlTabla,
                          params: _vm.paramsTabla,
                          actualizar: _vm.actualizarTabla,
                          bloquear: _vm.bloquearTabla
                        },
                        on: {
                          "update:actualizar": function($event) {
                            _vm.actualizarTabla = $event
                          },
                          "update:bloquear": function($event) {
                            _vm.bloquearTabla = $event
                          },
                          "tabla-cargada": function(datos) {
                            return _vm.cargarDatosEnCalendario(datos)
                          }
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "expand" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(props) {
                                return _vm._l(props.row.detalleTurno, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticStyle: {
                                        display: "flex",
                                        "margin-bottom": "10px"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            margin: "auto",
                                            "margin-left": "30px"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-row",
                                            { attrs: { gutter: 20 } },
                                            _vm._l(
                                              item.detalleTurnoTecnico,
                                              function(itemTT, index) {
                                                return _c(
                                                  "el-col",
                                                  {
                                                    key: index,
                                                    attrs: { span: 4 }
                                                  },
                                                  [
                                                    itemTT.disponible
                                                      ? _c(
                                                          "el-tag",
                                                          {
                                                            attrs: {
                                                              effect: "plain",
                                                              plain: ""
                                                            }
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$maca.common.formatearHora(
                                                                      item.horaInicio
                                                                    ) +
                                                                      " - " +
                                                                      _vm.$maca.common.formatearHora(
                                                                        item.horaFin
                                                                      ) +
                                                                      " - "
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]),
                                                            itemTT.detalleUser !=
                                                            null
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        itemTT
                                                                          .detalleUser
                                                                          .username
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ])
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    !itemTT.disponible
                                                      ? _c(
                                                          "el-tag",
                                                          {
                                                            attrs: {
                                                              effect: "light"
                                                            }
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$maca.common.formatearHora(
                                                                      item.horaInicio
                                                                    ) +
                                                                      " - " +
                                                                      _vm.$maca.common.formatearHora(
                                                                        item.horaFin
                                                                      ) +
                                                                      " - "
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]),
                                                            itemTT.detalleUser !=
                                                            null
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        itemTT
                                                                          .detalleUser
                                                                          .username
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ])
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                })
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { "min-width": 120, label: "\n        Fecha" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(props) {
                                return [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$maca.common.formatearFecha(
                                          props.row.fecha
                                        )
                                      )
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { "min-width": 120, label: "Horario" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(props) {
                                return [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$maca.common.formatearHora(
                                          props.row.horaInicio
                                        ) +
                                          " - " +
                                          _vm.$maca.common.formatearHora(
                                            props.row.horaFin
                                          )
                                      )
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { "min-width": 120, label: "Provincia" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(props) {
                                return [
                                  props.row.detalleArea != null
                                    ? _c("div", [
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              props.row.detalleArea.descripcion
                                            )
                                          }
                                        })
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { "min-width": 120, label: "Localidad" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(props) {
                                return [
                                  props.row.detalleSubarea != null
                                    ? _c("div", [
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              props.row.detalleSubarea
                                                .descripcion
                                            )
                                          }
                                        })
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { "min-width": 120, label: "Barrios" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(props) {
                                return _vm._l(
                                  props.row.detalleTurnoSeccion,
                                  function(item, index) {
                                    return _c("p", { key: index }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.detalleSeccion.descripcion
                                          ) +
                                          " "
                                      )
                                    ])
                                  }
                                )
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "Borrar", width: "70" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(props) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "danger",
                                        circle: "",
                                        disabled: !_vm.$store.getters.tienePermiso(
                                          "B_USE"
                                        )
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.eliminar(props.row.id)
                                        }
                                      }
                                    },
                                    [_c("i", { staticClass: "el-icon-delete" })]
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-card",
                [
                  _c("h4", { staticStyle: { color: "#909399" } }, [
                    _vm._v("Turnos disponibles")
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "margin-top": "5px",
                        color: "#606266"
                      }
                    },
                    [
                      _c("div", { staticClass: "circuloVerde" }),
                      _c("span", [_vm._v(" + 75%")])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "margin-top": "5px",
                        color: "#606266"
                      }
                    },
                    [
                      _c("div", { staticClass: "circuloAmarillo" }),
                      _c("span", [_vm._v(" + 50%")])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "margin-top": "5px",
                        color: "#606266"
                      }
                    },
                    [
                      _c("div", { staticClass: "circuloNaranja" }),
                      _c("span", [_vm._v(" + 25%")])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "margin-top": "5px",
                        "margin-bottom": "20px",
                        color: "#606266"
                      }
                    },
                    [
                      _c("div", { staticClass: "circuloRojo" }),
                      _c("span", [_vm._v(" - 25%")])
                    ]
                  ),
                  _c("vc-date-picker", {
                    attrs: {
                      rows: 2,
                      "is-expanded": "",
                      attributes: _vm.attrs
                    },
                    model: {
                      value: _vm.fecha,
                      callback: function($$v) {
                        _vm.fecha = $$v
                      },
                      expression: "fecha"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("modal-nuevo", {
        ref: "modalNuevo",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      }),
      _c("modal-modificar", {
        ref: "modalModificar",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }